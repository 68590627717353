//
// react-table.scss
//

.react-table-search {
    max-width: 250px;
}

.react-table {
    th,
    td {
        white-space: nowrap;
    }
    th {
        padding-left: $table-cell-padding-y;
        padding-right: 30px;
    }

    .sortable {
        position: relative;
        cursor: pointer;
        &:before {
            position: absolute;
            right: 0.45rem;
            left: auto;
            content: "\F0360";
            font-family: "Material Design Icons";
            font-size: 1rem;
            top: 10px;
            opacity: 0.3;
        }
        &:after {
            position: absolute;
            right: 0.45rem;
            left: auto;
            content: "\F035D";
            font-family: "Material Design Icons";
            top: 17px;
            opacity: 0.3;
            font-size: 1rem;
        }
        &.sorting_asc {
            &:before {
                opacity: 1;
            }
        }
        &.sorting_desc {
            &:after {
                opacity: 1;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        li.paginate_button.previous,
        li.paginate_button.next {
            display: inline-block;
            font-size: 1.5rem;
        }
    }
}
